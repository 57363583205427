import { CSS_VARIABLE_PREFIX } from '@/config';
import { toCamelCase } from '@/utils';
import { GlobalToken, theme } from '@df/toco-ui';
import { useMemo } from 'react';

const { useToken } = theme;

/**
 * 这里把主题token注入css全局变量
 */
export function CSSVariablesStyle() {
  const { token } = useToken();

  const globalVars = useMemo(() => {
    const varLines: string[] = [];

    Object.keys(token).forEach((key) => {
      const tokenValue = token[key as keyof GlobalToken];
      const formatTokenValue = (v) =>
        typeof v === 'number' && Number.isInteger(v) ? v + 'px' : v;
      if (tokenValue) {
        if (typeof tokenValue === 'object') {
          // 组件token
          Object.keys(tokenValue).forEach((name) => {
            const cpTokenValue = tokenValue[name];
            varLines.push(
              `--${CSS_VARIABLE_PREFIX}-${toCamelCase(`${key.toLocaleLowerCase()}-${name}`)}: ${formatTokenValue(cpTokenValue)};`,
            );
          });
        } else {
          // 全局token
          varLines.push(
            `--${CSS_VARIABLE_PREFIX}-${toCamelCase(key)}: ${formatTokenValue(tokenValue)};`,
          );
        }
      }
    });

    return `html {
      ${varLines.join('\n')}
    }`;
  }, [token]);

  return <style>{globalVars}</style>;
}
