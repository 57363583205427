import { store } from '@/app/root';
import AppWrapper from '@/components/app-wrapper';
import '@/style/reset.css';
import service from '@vs/service';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './service.ts';

const container = document.getElementById('root')!;
const root = createRoot(container);

service.defaults.withCredentials = true;
service.interceptors.response.use((res) => {
  const status = res?.data?.code;
  if (status === 401 && res?.data?.data?.redirectUrl) {
    window.location.href = res?.data?.data?.redirectUrl; // 重定向到新的页面
  }

  return res;
});

root.render(
  <React.StrictMode>
    <AppWrapper store={store}>
      <App />
    </AppWrapper>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { store };
