// 将下划线或者中划线命名转换为驼峰命名
export const toCamelCase = (str) => {
  return str
    .replace(/[_-]([a-zA-Z])/g, (match, p1) => p1.toUpperCase())
    .replace(/^[A-Z]/, (match) => match.toLowerCase());
};

// 将下划线命名转换为驼峰命名， 首字母大写
export const toPascalCase = (str) => {
  return str.replace(/(^|[_-])([a-z])/g, (match, p1, p2) => p2.toUpperCase());
};
