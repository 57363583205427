import { selectCommon, setCurrentOrg } from '@/app/common';
import { AddForm } from '@/modules/organization';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Image, Input, Popover } from '@df/toco-ui';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.css';

type ModuleInfoType = {
  name?: string;
  isPerson?: boolean;
  logo?: string;
};

type ChoosePropsType = {
  children?: React.ReactNode;
};

const Index = (props: ChoosePropsType) => {
  const { children } = props;
  const { currentOrg, organization } = useSelector(selectCommon);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState<ModuleInfoType | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleSetCurrentOrg = useCallback(
    (v) => {
      setCurrent(v);
      setTimeout(() => {
        dispatch(setCurrentOrg(v));
      }, 0);
    },
    [dispatch],
  );

  const handleToggleOrg = useCallback(
    (data) => {
      handleSetCurrentOrg(data);
      setOpen(false);
    },
    [handleSetCurrentOrg],
  );

  const handleClickAdd = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (currentOrg) {
      setCurrent(currentOrg);
    }
  }, [currentOrg]);

  const renderRole = (v) => {
    let value = '';
    switch (v) {
      case 'SUPER_ADMIN':
        value = '超级管理员';
        break;
      case 'ADMIN':
        value = '管理员';
        break;
      case 'PARTICIPATE':
        value = '组织成员';
        break;
      case 'TEMPORARY':
        value = '临时成员';
        break;
    }
    return value;
  };

  const renderContent = useCallback(() => {
    const target = [...(organization ?? [])];
    return (
      <div className={styles?.['module-container']}>
        <div className={styles?.['module-search']}>
          <Input prefix={<SearchOutlined />} placeholder="搜索组织" />
        </div>
        <div className={styles?.['module-main-container']}>
          {target?.map((item) => {
            return (
              <div
                key={item?.id}
                className={classNames(styles?.['module-item'], {
                  [styles?.['module-item-active']]:
                    item?.organizationId === currentOrg?.organizationId,
                })}
                onClick={() => {
                  handleToggleOrg(item);
                }}
              >
                {item?.logo ? (
                  <span>
                    <Image
                      src={item?.logo}
                      style={{
                        width: 20,
                        height: 20,
                      }}
                      preview={false}
                    />
                  </span>
                ) : (
                  <span
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  ></span>
                )}

                <div className={styles?.['module-item-block']}>
                  <span className={styles?.['module-item-name']}>
                    {item?.name}
                  </span>
                  <span className={styles?.['module-item-role']}>
                    {renderRole(item?.role)}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles?.['module-item-add']}>
          <AddForm
            triggerNode={
              <div onClick={handleClickAdd}>
                <PlusOutlined />
                <span>新建组织</span>
              </div>
            }
          />
        </div>
      </div>
    );
  }, [currentOrg, handleToggleOrg, organization]);

  return (
    <div className={styles?.['change-module']}>
      <Popover
        placement="rightTop"
        title=""
        content={renderContent()}
        trigger={['click']}
        open={open}
        onOpenChange={(v) => {
          setOpen(v);
        }}
        overlayClassName={styles?.['change-module-popover']}
        arrow={false}
      >
        {children}
      </Popover>
    </div>
  );
};

export default Index;
