import { Button, Space } from 'antd';
import axios from 'axios';
import yaml from 'js-yaml';
import React, { useEffect, useState } from 'react';

type File = { url: string };

type App = {
  version: string;
  url: string;
  files?: File[];
};

const host = window.location.host?.includes('think1024')
  ? `https://tocode-release.oss-cn-hangzhou.aliyuncs.com/tocode-release`
  : '/download';

const DownloadApp: React.FC = () => {
  const [windows, setWindows] = useState<App>();
  const [macOS, setMacOS] = useState<App>();
  const [linux, setLinux] = useState<App>();

  useEffect(() => {
    const headers = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    };
    axios.get(`${host}/latest.yml`, { headers }).then((res) => {
      setWindows(yaml.load(res.data) as App);
    });
    axios.get(`${host}/latest-mac.yml`, { headers }).then((res) => {
      setMacOS(yaml.load(res.data) as App);
    });
    axios.get(`${host}/latest-linux.yml`, { headers }).then((res) => {
      setLinux(yaml.load(res.data) as App);
    });
  }, []);

  const download = (file?: File) => {
    if (file) {
      // 这是对的，别改
      window.open(`${host}/${file.url}`);
    }
  };

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      {windows && (
        <Button
          style={{ width: '100%' }}
          onClick={() => download(windows.files?.[0])}
        >{`Windows ${windows.version}`}</Button>
      )}
      {macOS && (
        <Button
          style={{ width: '100%' }}
          onClick={() =>
            download(macOS.files?.find((file) => !file.url.includes('arm64')))
          }
        >{`macOS(intel) ${macOS.version}`}</Button>
      )}
      {macOS && (
        <Button
          style={{ width: '100%' }}
          onClick={() =>
            download(macOS.files?.find((file) => file.url.includes('arm64')))
          }
        >{`macOS(arm64) ${macOS.version}`}</Button>
      )}
      {linux && (
        <Button
          style={{ width: '100%' }}
          onClick={() => download(linux.files?.[0])}
        >{`Linux ${linux.version}`}</Button>
      )}
    </Space>
  );
};

export default DownloadApp;
