import { createServices } from '@vs/service';
export default createServices({
  VsProjectMainController_addUserToProject_15a118: {
    method: 'post',
    url: '/api/vs-project-main/add-user-to-project',
    parameterFomatter: (data?: {
      addUserToProjectBto: AddUserToProjectBtoVsProjectMainServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ProjectVoVsProjectMainEntranceWebVo) =>
      data,
  },
  VsProjectMainController_completeInvite_30ba77: {
    method: 'post',
    url: '/api/vs-project-main/complete-invite',
    parameterFomatter: (data?: { inviteCode: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  VsProjectMainController_createProject_10ac58: {
    method: 'post',
    url: '/api/vs-project-main/create-project',
    parameterFomatter: (data?: {
      createProjectBto: CreateProjectBtoVsProjectMainServiceBto;
      extApiParam: {
        dbType: string;
        organizationId: number;
      };
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  VsProjectMainController_generateInviteCode_4d35dd: {
    method: 'post',
    url: '/api/vs-project-main/generate-invite-code',
    parameterFomatter: (data?: { projectId: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  VsProjectMainController_getInviteInfo_05abe9: {
    method: 'post',
    url: '/api/vs-project-main/get-invite-info',
    parameterFomatter: (data?: { inviteCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectInviteInfoVoVsProjectMainEntranceWebVo,
    ) => data,
  },
  VsProjectMainController_getProjectById_bf656a: {
    method: 'post',
    url: '/api/vs-project-main/get-project-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: ProjectVoVsProjectMainEntranceWebVo) =>
      data,
  },
  VsProjectMainController_queryProjectByOrganizationPaged_4494f9: {
    method: 'post',
    url: '/api/vs-project-main/query-project-by-organization-paged',
    parameterFomatter: (data?: {
      qto: ProjectByOrganizationQtoVsProjectMainPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ProjectVoVsProjectMainEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  VsProjectMainController_queryProjectTagByOrganization_e9ba83: {
    method: 'post',
    url: '/api/vs-project-main/query-project-tag-by-organization',
    parameterFomatter: (data?: {
      qto: ProjectTagByOrganizationQtoVsProjectMainPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectTagVoVsProjectMainEntranceWebVo[],
    ) => data,
  },
  VsProjectMainController_queryProjectUuidNameByUser_1fc834: {
    method: 'post',
    url: '/api/vs-project-main/query-project-uuid-name-by-user',
    parameterFomatter: (data?: {
      qto: ProjectUuidNameByUserQtoVsProjectMainPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectUuidNameVoVsProjectMainEntranceWebVo[],
    ) => data,
  },
  VsProjectMainController_queryProjectWithOrganizationByUser_510181: {
    method: 'post',
    url: '/api/vs-project-main/query-project-with-organization-by-user',
    parameterFomatter: (data?: {
      qto: ProjectWithOrganizationByUserQtoVsProjectMainPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectWithOrganizationVoVsProjectMainEntranceWebVo[],
    ) => data,
  },
  VsProjectMainController_queryUserByProjectPaged_75638d: {
    method: 'post',
    url: '/api/vs-project-main/query-user-by-project-paged',
    parameterFomatter: (data?: {
      qto: UserByProjectQtoVsProjectMainPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ProjectVsUserVoVsProjectMainEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  VsProjectMainController_removeUserFromProject_7499f8: {
    method: 'post',
    url: '/api/vs-project-main/remove-user-from-project',
    parameterFomatter: (data?: {
      removeUserFromProjectBto: RemoveUserFromProjectBtoVsProjectMainServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ProjectVoVsProjectMainEntranceWebVo) =>
      data,
  },
  VsProjectMainController_transferProject_0865c9: {
    method: 'post',
    url: '/api/vs-project-main/transfer-project',
    parameterFomatter: (data?: { projectId: number; toUserId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  VsProjectMainController_updateProject_4c802d: {
    method: 'post',
    url: '/api/vs-project-main/update-project',
    parameterFomatter: (data?: {
      updateProjectBto: UpdateProjectBtoVsProjectMainServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  VsProjectMainController_visitProject_5cd1a9: {
    method: 'post',
    url: '/api/vs-project-main/visit-project',
    parameterFomatter: (data?: { projectId: string }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  UserAccountController_getAccountById_8ab15e: {
    method: 'post',
    url: '/api/user-account/get-account-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: AccountVoUserAccountEntranceWebVo) =>
      data,
  },
  UserAccountController_getAccountInfo_11ced6: {
    method: 'post',
    url: '/api/user-account/get-account-info',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: AccountVoUserAccountEntranceWebVo) =>
      data,
  },
  UserAccountController_getOssPolicy_eaa6a4: {
    method: 'post',
    url: '/api/user-account/get-oss-policy',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: OssPolicyVoUserAccountEntranceWebVo) =>
      data,
  },
  UserAccountController_logout_7c3e9b: {
    method: 'post',
    url: '/api/user-account/logout',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  UserAccountController_queryAccountVoByAccountByOrganizationPaged_d4e3ce: {
    method: 'post',
    url: '/api/user-account/query-query-account-vo-by-account-by-organization-paged-by-{}{}',
    parameterFomatter: (data?: {
      qto: AccountByOrganizationQtoUserAccountPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: AccountVoUserAccountEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserAccountController_updateAccount_6e3982: {
    method: 'post',
    url: '/api/user-account/update-account',
    parameterFomatter: (data?: {
      btoParam: UpdateAccountBtoUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: AccountVoUserAccountEntranceWebVo) =>
      data,
  },
  UserAccountController_updateAccountStatus_0de17c: {
    method: 'post',
    url: '/api/user-account/update-account-status',
    parameterFomatter: (data?: {
      btoParam: UpdateAccountStatusBtoUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: AccountVoUserAccountEntranceWebVo) =>
      data,
  },
  UserLoginController_phoneAndVerifyLogin_825683: {
    method: 'post',
    url: '/api/user-account/phone-and-verify-login',
    parameterFomatter: (data?: { phone: string; verify: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  UserLoginController_sendVerifyCode_963063: {
    method: 'post',
    url: '/api/user-account/send-verify-code',
    parameterFomatter: (data?: { phone: string; model: string }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  UserLoginController_userPasswordLogin_07931d: {
    method: 'post',
    url: '/api/user-account/user-password-login',
    parameterFomatter: (data?: { username: string; password: string }) => data,
    responseFormatter: (_, __, data?: AccountVoUserAccountEntranceWebVo) =>
      data,
  },
  VsProjectMainController_hasProjectPermission_c5e57d: {
    method: 'post',
    url: '/api/project-main/has-project-permission',
    parameterFomatter: (data?: { projectId: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  VsProjectMainController_isProjectOwner_4d3c68: {
    method: 'post',
    url: '/api/project-main/is-project-owner',
    parameterFomatter: (data?: { projectId: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  VsProjectMainController_getProjectSimpleByUser_655320: {
    method: 'post',
    url: '/api/project/list/by/user',
    parameterFomatter: (data?: { userId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectUuidNameVoVsProjectMainEntranceWebVo[],
    ) => data,
  },
  OrganizationController_transferOrganization_ceaf1c: {
    method: 'post',
    url: '/api/organization-entrance-web/transfer-organization',
    parameterFomatter: (data?: { organizationId: number; toUserId: number }) =>
      data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_addUserToOrganization_b4a1db: {
    method: 'post',
    url: '/api/organization/add-user-to-organization',
    parameterFomatter: (data?: {
      organizationVsUserBto: OrganizationVsUserBtoOrganizationServiceBtoAddusertoorganizationbto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OrganizationController_completeInvite_8be634: {
    method: 'post',
    url: '/api/organization/complete-invite',
    parameterFomatter: (data?: { inviteCode: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_createOrganization_fba0fb: {
    method: 'post',
    url: '/api/organization/create-organization',
    parameterFomatter: (data?: {
      createOrganizationBto: CreateOrganizationBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVoOrganizationEntranceWebVo,
    ) => data,
  },
  OrganizationController_generateOrganizationInviteCode_cf5498: {
    method: 'post',
    url: '/api/organization/generate-invite-code',
    parameterFomatter: (data?: {
      organizationId: number;
      role: OrganizationRoleEnum;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  OrganizationController_getAllRoleTag_243b69: {
    method: 'post',
    url: '/api/organization/get-all-role-tag',
    parameterFomatter: (data?: { qto: AllRoleTagQtoOrganizationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationRoleTagVoOrganizationEntranceWebVo[],
    ) => data,
  },
  OrganizationController_getInviteInfo_4dde0a: {
    method: 'post',
    url: '/api/organization/get-invite-info',
    parameterFomatter: (data?: { inviteCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationInviteInfoVoOrganizationEntranceWebVo,
    ) => data,
  },
  OrganizationController_getOrganizationVoById_d66224: {
    method: 'post',
    url: '/api/organization/get-organization-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVoOrganizationEntranceWebVo,
    ) => data,
  },
  OrganizationController_queryOrganizationByUserIdPaged_5b568d: {
    method: 'post',
    url: '/api/organization/query-organization-by-user-id-paged',
    parameterFomatter: (data?: {
      qto: OrganizationByUserIdQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OrganizationVoOrganizationEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrganizationController_queryOrganizationUserInfoByUser_ea4149: {
    method: 'post',
    url: '/api/organization/query-organization-user-info-by-user',
    parameterFomatter: (data?: {
      qto: OrganizationUserInfoByUserQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVsUserInfoVoOrganizationEntranceWebVo[],
    ) => data,
  },
  OrganizationController_queryOrganizationVsProjectByUser_86868c: {
    method: 'post',
    url: '/api/organization/query-organization-vs-project-by-user',
    parameterFomatter: (data?: {
      qto: OrganizationVsProjectByUserQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVsProjectVoOrganizationEntranceWebVo[],
    ) => data,
  },
  OrganizationController_queryOrganizationVsProjectVoByOrganizationProjectListPaged_72ad30:
    {
      method: 'post',
      url: '/api/organization/query-organization-vs-project-vo-by-organization-project-list-paged',
      parameterFomatter: (data?: {
        qto: OrganizationProjectListQtoOrganizationPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OrganizationVsProjectVoOrganizationEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  OrganizationController_queryOrganizationVsProjectVoByOrganizationProjectListWaterfall_f3a08f:
    {
      method: 'post',
      url: '/api/organization/query-organization-vs-project-vo-by-organization-project-list-waterfall',
      parameterFomatter: (data?: {
        qto: OrganizationProjectListQtoOrganizationPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OrganizationVsProjectVoOrganizationEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  OrganizationController_queryOrganizationVsUserVoByOrganizationUserListPaged_21b390:
    {
      method: 'post',
      url: '/api/organization/query-organization-vs-user-vo-by-organization-user-list-paged',
      parameterFomatter: (data?: {
        qto: OrganizationUserListQtoOrganizationPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OrganizationVsUserVoOrganizationEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  OrganizationController_queryOrganizationVsUserVoByOrganizationUserListWaterfall_9fb679:
    {
      method: 'post',
      url: '/api/organization/query-organization-vs-user-vo-by-organization-user-list-waterfall',
      parameterFomatter: (data?: {
        qto: OrganizationUserListQtoOrganizationPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OrganizationVsUserVoOrganizationEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  OrganizationController_queryProjectTransferRecordByUserPaged_230c4e: {
    method: 'post',
    url: '/api/organization/query-project-transfer-record-by-user-paged',
    parameterFomatter: (data?: {
      qto: ProjectTransferRecordByUserQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ProjectTransferRecordVoOrganizationEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrganizationController_removeUserFromOrganization_ae67f6: {
    method: 'post',
    url: '/api/organization/remove-user-from-organization',
    parameterFomatter: (data?: {
      organizationVsUserBto: OrganizationVsUserBtoOrganizationServiceBtoAddusertoorganizationbto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OrganizationController_transferProjectApply_9f315b: {
    method: 'post',
    url: '/api/organization/transfer-project-apply',
    parameterFomatter: (data?: {
      projectId: number;
      toUserId: number;
      toOrganizationId: number;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_transferProjectConfirm_6b3530: {
    method: 'post',
    url: '/api/organization/transfer-project-confirm',
    parameterFomatter: (data?: { transferRecordId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_updateOrganization_912329: {
    method: 'post',
    url: '/api/organization/update-organization',
    parameterFomatter: (data?: {
      updateOrganizationBto: UpdateOrganizationBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVoOrganizationEntranceWebVo,
    ) => data,
  },
  OrganizationController_updateUserInOrganization_abfea2: {
    method: 'post',
    url: '/api/organization/update-user-in-organization',
    parameterFomatter: (data?: {
      organizationVsUserBto: OrganizationVsUserBtoOrganizationServiceBtoAddusertoorganizationbto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OrganizationController_getOrganizationOfRegion_efc270: {
    method: 'post',
    url: '/api/organization/get-organization-of-region',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVoOrganizationEntranceWebVo[],
    ) => data,
  },
  OrganizationController_getOrganizationRole_ee9a45: {
    method: 'post',
    url: '/api/organization/role',
    parameterFomatter: (data?: { organizationId: number }) => data,
    responseFormatter: (_, __, data?: OrganizationRoleEnum) => data,
  },
});
